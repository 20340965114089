import "linq-typed";
import { observable, action, runInAction } from "mobx";
import GroupModel from "../models/groupmodel";
import ItemModel from "../models/itemmodel";

export default class Store {
    @observable
    public groupModels: GroupModel[] = [];

    @observable
    public groupSelected: GroupModel = null;

    @observable
    public imageSelected: ItemModel = null;

    private async getConfiguration(): Promise<GroupModel[]> {
        const response = await fetch("/configuration.json");
        const stringResponse = await response.text();
        return await JSON.parse(stringResponse);
    }

    private async loadConfiguration() {
        const result = await this.getConfiguration();

        runInAction(() => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const galleryId = params.get("id");

            this.groupModels = result;

            if (galleryId !== null && this.groupModels !== null) {
                this.groupSelected = this.groupModels.FirstOrDefault(x => x.items.Any(i => i.id === galleryId));
                if (this.groupSelected !== null) {
                    this.imageSelected = this.groupSelected.items.FirstOrDefault(x => x.id === galleryId);
                }
            } else {
                if (this.groupModels !== null && this.groupModels.length > 0) {
                    this.groupSelected = this.groupModels[0];
                }
            }
        });
    }

    constructor() {
        this.loadConfiguration();
    }

    @action
    public selectGallery(gallery: GroupModel): void {
        this.groupSelected = gallery;
    }

    @action
    public openPhoto(item: ItemModel): void {
        history.pushState(null, null, "/?id=" + item.id);
        this.imageSelected = item;
    }

    @action
    public closePhoto(): void {
        history.pushState(null, null, "/");
        this.imageSelected = null;
    }

    @action
    public nextPhoto(): void {
        let index = this.groupSelected.items.IndexOf(this.imageSelected);
        if (index >= this.groupSelected.items.length - 1) {
            index = 0;
            this.imageSelected = this.groupSelected.items[index];
        } else {
            index++;
            this.imageSelected = this.groupSelected.items[index];
        }
    }

    @action
    public prevPhoto(): void {
        let index = this.groupSelected.items.IndexOf(this.imageSelected);
        if (index > 0) {
            index--;
            this.imageSelected = this.groupSelected.items[index];
        } else {
            index = this.groupSelected.items.length - 1;
            this.imageSelected = this.groupSelected.items[index];
        }
    }
}