import * as React from "react";
import Store from "./stores/store";
import { observer } from "mobx-react";

@observer
export default class PhotoView extends React.Component<{ store: Store }> {
    public render() {
        return (
            <div className="photoview">
                <div className="photoview-photo" style={{ backgroundImage: "url('" + this.props.store.imageSelected.src + "')" }}>
                    <div className="photoview-photo-label">
                        <div className="photoview-photo-title">{this.props.store.imageSelected.title}</div>
                        <div className="photoview-photo-desc">{this.props.store.imageSelected.description}</div>
                    </div>
                </div>
                <div className="photoview-close" onClick={() => this.props.store.closePhoto()}>×</div>
                <div className="photoview-prev" onClick={() => this.props.store.prevPhoto()}>◄</div>
                <div className="photoview-next" onClick={() => this.props.store.nextPhoto()}>►</div>
            </div>
        );
    }
}