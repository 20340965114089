import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as mobx from "mobx";

mobx.configure({ enforceActions: "observed" });

import "./assets/styles/flexbox.scss";
import "./assets/styles/layout.scss";
import Layout from "./layout";
import Store from "./stores/store";

const store = new Store();

ReactDOM.render(
    <BrowserRouter>
        <Layout store={store} />
    </BrowserRouter>,
    document.getElementById("root"));