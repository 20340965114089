import * as React from "react";
import Menu from "./menu";
import Header from "./header";
import Gallery from "./gallery";
import Footer from "./footer";
import Store from "./stores/store";
import { observer } from "mobx-react";
import PhotoView from "./photoview";

@observer
export default class Layout extends React.Component<{ store: Store }> {
    public render() {
        return (
            <div className="content">
                <Header />
                <Menu store={this.props.store} />
                <Gallery store={this.props.store} />
                <Footer />

                {this.props.store.imageSelected && <PhotoView store={this.props.store} />}
            </div>
        );
    }
}