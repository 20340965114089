import * as React from "react";
import Store from "./stores/store";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

@observer
export default class Gallery extends React.Component<{ store: Store }> {
    public render() {
        return (
            <div className="gallery row">
                {
                    this.props.store.groupSelected && this.props.store.groupSelected.items.map((item) => {
                        return (
                            <div className="gallery-box col-xs-12 col-sm-6 col-md-3" onClick={() => this.props.store.openPhoto(item)}>
                                <img className="gallery-item" src={item.thumbSrc} />
                                <div className="gallery-item-label">
                                    <div className="gallery-item-title">{item.title}</div>
                                    <div className="gallery-item-desc">{item.description}</div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}