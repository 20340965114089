import * as React from "react";
import { observer } from "mobx-react";
import Store from "./stores/store";

@observer
export default class Menu extends React.Component<{ store: Store }> {
    public render() {
        return (
            <div className="menu">
                {
                    this.props.store.groupModels && this.props.store.groupModels.map((i) => {
                        return <div className="menu-item" onClick={() => this.props.store.selectGallery(i)}>{i.name}</div>;
                    })
                }
            </div>
        );
    }
}